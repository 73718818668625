<template>
  <div class="table">
    <div class="tablebox">
      <el-table
        :data="tableData"
        style="width: 100%"
        border
      >
        <el-table-column
          prop="applyDate"
          label="申请时间"
        >
        </el-table-column>
        <el-table-column prop="deptName" label="申请部门"> </el-table-column>
        <el-table-column prop="nickName" label="申请人">
        </el-table-column>
        <el-table-column prop="companyName" label="审批企业"> </el-table-column>
        <el-table-column prop="enforceType" label="执法类型"> </el-table-column>
        <el-table-column prop="entouragePhone" label="陪同人姓名"> </el-table-column>
        <el-table-column prop="applyContent" label="执法内容"> </el-table-column>
        <!-- <el-table-column prop="Approval" label="需分管副区长审批">
          <template slot-scope="scope">
            <el-checkbox v-model="scope.row.secondApproval" :disabled="false"
              >是否需要</el-checkbox
            >
          </template>
        </el-table-column> -->
        <el-table-column label="操作" width="250px">
          <template slot-scope="scope">
            <div v-if="scope.row.approvalResult == '待审核'">
              <el-button
                type="primary"
                size="mini"
                @click="handleUpdate(scope.row)"
              >
                同意
              </el-button>
              <el-button
                size="mini"
                type="danger"
                @click="handleDelete(scope.row)"
              >
                拒绝
              </el-button>
            </div>
            <div class="Yes" v-if="scope.row.approvalResult == '审批通过'">
              已通过
            </div>
            <div class="No" v-if="scope.row.approvalResult == '审批失败'">
              未通过
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="pageNum"
          :page-sizes="[5, 10, 20, 40]"
          :page-size="pageSize"
          layout="sizes, prev, pager, next"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 审批失败弹窗 -->
    <el-dialog title="拒绝" :visible.sync="dialogVisible" width="50%">
      <!-- 表单 -->
      <el-form
        :label-position="labelPosition"
        label-width="120px"
        :model="formLabelAlign"
      >
        <el-form-item label="拒绝原因">
          <el-input v-model="formLabelAlign.type"></el-input>
        </el-form-item>
      </el-form>
      <!-- 确认取消 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirm()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { GetmyApprovalList, Postapproval } from "@/request/api";
export default {
  name: "HtglTable",

  data() {
    return {
      input: "", //搜索内容
      tableData: [],
      dialogVisible: false, //弹出框判定
      labelPosition: "right",
      formLabelAlign: {
        type: "",
      },
      checked: false,
      id: "",
      secondApproval: "",
      currentPage: 1, //分页
      pageNum:1,
      pageSize: 10,
      total:1,
    };
  },

  methods: {
    // 通过
    handleUpdate(v) {
      // console.log(v);
      this.$confirm("是否让此条申请通过", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          Postapproval({
            approvalResult: "审批通过",
            id: v.id,
            rejectionReason: "",
            secondApproval: true,
          }).then((res) => {
            // console.log(res);
            if (res.code == 200) {
              this.seelist();
            } else {
              this.$message.error(res.message);
            }
          });
        })
        .catch(() => {});
    },
    // 拒绝
    handleDelete(v) {
      // console.log(v);
      this.id = v.id;
      this.secondApproval = true;
      this.formLabelAlign.type = "";
      this.dialogVisible = true;
    },
    // 拒绝弹窗确定
    confirm() {
      Postapproval({
        approvalResult: "审批失败",
        id: this.id,
        rejectionReason: this.formLabelAlign.type,
        secondApproval: this.secondApproval,
      }).then((res) => {
        // console.log(res);
        if (res.code == 200) {
          this.seelist();
          this.dialogVisible = false;
        }
      });
    },
    // 审批列表
    seelist() {
      GetmyApprovalList({
        approvalResult: "",
        companyName: "",
        enforceType: "",
        name: "",
        nickName: "",
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        rangDate: "",
      }).then((res) => {
        // console.log(res);
        if (res.code == 200) {
          this.tableData = res.data.list;
          this.currentPage = res.totalPage;
          this.total = res.data.total;
        }
      });
    },
    //分页
    handleSizeChange(val) {
      // console.log(val);
      this.pageSize = val;
      this.seelist();
    },
    handleCurrentChange(val) {
      // console.log(val);
      this.pageNum = val;
      this.seelist();
    },
  },

  mounted() {
    this.seelist(); // 审批列表
  },
};
</script>

<style lang="scss" scoped>
.table {
  width: 100%;
  .head {
    margin-bottom: 15px;
    display: flex;
  }
  .tablebox {
    width: 100%;
    padding: 10px;
    background-color: #fff;
  }
  .Yes {
    color: green;
  }
  .No {
    color: red;
  }
  .block {
    text-align: center;
    margin-top: 15px;
  }
}
</style>