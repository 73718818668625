<template>
  <div class="table">
    <div class="tablebox">
      <el-table
        :data="
          tableData.slice((currentPage - 1) * pageSize, currentPage * pageSize)
        "
        style="width: 100%"
        border
      >
        <el-table-column
          prop="createTime"
          label="审批时间"
        >
        </el-table-column>
        <el-table-column prop="approvalUserName" label="审批人">
        </el-table-column>
        <el-table-column prop="companyName" label="审批企业"> </el-table-column>
        <el-table-column prop="deptName" label="申请部门"> </el-table-column>
        <el-table-column prop="enforceType" label="执法类型"> </el-table-column>
        <el-table-column prop="applyContent" label="执法内容"> </el-table-column>
        <el-table-column label="操作" width="250px">
          <template slot-scope="scope">
            <div v-if="scope.row.secondApprovalResult == '待审核'">
              <el-button
                type="primary"
                size="mini"
                @click="handleUpdate(scope.row)"
              >
                同意
              </el-button>
              <el-button
                size="mini"
                type="danger"
                @click="handleDelete(scope.row)"
              >
                拒绝
              </el-button>
            </div>
            <div class="Yes" v-if="scope.row.secondApprovalResult == '审批通过'">
              已通过
            </div>
            <div class="No" v-if="scope.row.secondApprovalResult == '审批失败'">
              未通过
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage"
          :page-sizes="[5, 10, 20, 40]"
          :page-size="pageSize"
          layout="sizes, prev, pager, next"
          :total="tableData.length"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 审批失败弹窗 -->
    <el-dialog title="拒绝" :visible.sync="dialogVisible" width="50%">
      <!-- 表单 -->
      <el-form
        :label-position="labelPosition"
        label-width="120px"
        :model="formLabelAlign"
      >
        <el-form-item label="拒绝原因">
          <el-input v-model="formLabelAlign.type"></el-input>
        </el-form-item>
      </el-form>
      <!-- 确认取消 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirm()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { GetmyApprovalList, Postapproval } from "@/request/api";
export default {
  name: "HtglTable",

  data() {
    return {
      input: "", //搜索内容
      tableData: [],
      dialogVisible: false, //弹出框判定
      labelPosition: "right",
      formLabelAlign: {
        type: "",
      },
      checked: false,
      id: "",
      secondApproval: "",
      currentPage: 1, //分页
      pageSize: 10,
    };
  },

  methods: {
    formatTime(row, column) {
      let data = row[column.property];
      let dtime = new Date(data);
      const year = dtime.getFullYear();
      let month = dtime.getMonth() + 1;
      if (month < 10) {
        month = "0" + month;
      }
      let day = dtime.getDate();
      if (day < 10) {
        day = "0" + day;
      }
      let hour = dtime.getHours();
      if (hour < 10) {
        hour = "0" + hour;
      }
      let minute = dtime.getMinutes();
      if (minute < 10) {
        minute = "0" + minute;
      }
      let second = dtime.getSeconds();
      if (second < 10) {
        second = "0" + second;
      }
      return (
        year +
        "-" +
        month +
        "-" +
        day +
        " " +
        hour +
        ":" +
        minute +
        ":" +
        second
      );
    },
    // 通过
    handleUpdate(v) {
      // console.log(v);
      this.$confirm("是否让此条申请通过", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          Postapproval({
            approvalResult: "审批通过",
            id: v.id,
            rejectionReason: "",
            // secondApproval: v.secondApproval,
          }).then((res) => {
            // console.log(res);
            if (res.code == 200) {
              this.seelist();
            } else {
              this.$message.error(res.message);
            }
          });
        })
        .catch(() => {});
    },
    // 拒绝
    handleDelete(v) {
      // console.log(v);
      this.id = v.id;
      // this.secondApproval = v.secondApproval;
      this.formLabelAlign.type = "";
      this.dialogVisible = true;
    },
    // 拒绝弹窗确定
    confirm() {
      Postapproval({
        approvalResult: "审批失败",
        id: this.id,
        rejectionReason: this.formLabelAlign.type,
        // secondApproval: this.secondApproval,
      }).then((res) => {
        // console.log(res);
        if (res.code == 200) {
          this.seelist();
          this.dialogVisible = false;
        }
      });
    },
    // 审批列表
    seelist() {
      GetmyApprovalList({
        approvalResult: "",
        companyName: "",
        enforceType: "",
        name: "",
        nickName: "",
        pageNum: this.currentPage,
        pageSize: 10000,
        rangDate: "",
      }).then((res) => {
        // console.log(res);
        if (res.code == 200) {
          this.tableData = res.data.list;
        }
      });
    },
    handleSizeChange(val) {
      // console.log(val);
      this.pageSize = val;
    },
    handleCurrentChange(val) {
      // console.log(val);
      this.currentPage = val;
    },
  },

  mounted() {
    this.seelist(); // 审批列表
  },
};
</script>

<style lang="scss" scoped>
.table {
  width: 100%;
  .head {
    margin-bottom: 15px;
    display: flex;
  }
  .tablebox {
    width: 100%;
    padding: 10px;
    background-color: #fff;
  }
  .Yes {
    color: green;
  }
  .No {
    color: red;
  }
  .block {
    text-align: center;
    margin-top: 15px;
  }
}
</style>